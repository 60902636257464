.alert {
    background: transparent;
    border: 1px solid var(--cl-fontDark);
    border-radius: 0.5rem;
    padding-block: 1rem;
    padding-inline: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--cl-fontDark);
}

.alert.float {
    @extend .alert;
    position: absolute;
    top: 1.8rem;
    left: 50%;
    transform: translateX(-50%);
    color: red;
    min-width: 40rem;
    z-index: 1000;
}
.alert__text {
    font-size: var(--fontSize-small);
    line-height: 2.2rem;

    strong {
        font-weight: 700;
    }
}
.alert__close {
    position: relative;
    height: 2.2rem;
    width: 2.2rem;
    font-size: 2.2rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-left: var(--size-24);
    border: none;
    background: transparent;
    color: inherit;
}
.alert__close input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}
.alert__closeIcon {
    height: 2.2rem;
    width: 2.2rem;
}

.alert.hide {
    display: none;
}

.alert.primary {
    background: var(--cl-primaryTransparent);
    border-color: var(--cl-primary);
    color: var(--cl-primaryDark);
}
.alert.danger {
    background-color: var(--cl-dangerTransparent);
    border-color: var(--cl-danger);
    color: var(--cl-danger);
}

.alert.center {
    justify-content: center;
}
