.col-span-2 {
    grid-column: span 2;
}

.col-span-3 {
    grid-column: span 3;
}

.col-span-full {
    grid-column: span 4;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
