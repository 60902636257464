.errorScreen {
    position: relative;
    min-height: 29rem;
    height: 50%;
    max-height: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--size-24);

    background: var(--cl-light);
    box-shadow: 0 var(--size-4) var(--size-8) rgba(0, 0, 0, 5%);
    border-radius: var(--size-4);
}

.errorScreen .icon {
    color: var(--cl-danger);
    font-size: var(--fontSize-large);
}

.errorScreen__message {
    font-size: var(--fontSize-main);
    font-weight: var(--fontWeight-bold);
}

.errorScreen__message .navigation__link {
    font-size: inherit;
}

.errorScreen [class^="button--"] {
    font-weight: var(--fontWeight-medium);
}
