.resetPasswordPage {
    .button--primary {
        margin-inline-start: auto;
        margin-inline-end: auto;
    }

    .alert {
        margin-block-start: 3.5rem;
    }
}
