.successPage {
    .login__icon-success {
        margin-block-start: 3.2rem;
        margin-block-end: var(--size-48);
        font-size: 8.8rem;
        color: var(--cl-success-dark);
    }

    .formText:not(:last-child) {
        font-size: var(--fontSize-main);
        line-height: 1.3em;
    }
    .formText:last-child {
        margin-block-start: var(--size-48);
    }

    .formText > .formLink {
        font-size: inherit;
        color: var(--cl-primary);
        text-decoration: none;
    }
}
