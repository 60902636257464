@use "../../../styles/abstract" as *;

.login__content-section {
    width: 100%;
    background: var(--cl-backgroundDashboard);
    padding-block: var(--size-48);
    padding-inline: var(--size-48);
    text-align: center;

    @include responsive-max(768px) {
        padding-block: 4rem;
        padding-inline: 2.5rem;
    }
}

.login__content-section .alert {
    margin-block-start: 2rem;
    margin-block-end: -1rem;
}

.registerLink {
    color: var(--cl-primary);
    text-decoration: none;
}

.registerText {
    margin-top: var(--size-16);
    line-height: var(--size-16);
    font-size: var(--fontSize-medium);
    color: var(--cl-fontGrey);
}
