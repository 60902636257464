.home {
    &__header {
        text-align: center;
    }

    & &__header {
        margin-block-start: 5.5rem;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 4.8rem;
    }

    &-title {
        font-size: 2rem;
        line-height: 1.1em;
        font-weight: var(--fontWeight-bold);
        margin-bottom: var(--size-24);
    }

    &__grettings {
        font-size: 3rem;
        font-weight: var(--fontWeight-bold);
        line-height: 0.73em;
    }

    &__header &__grettings {
        margin-bottom: 1.5rem;
    }

    &__logInfos {
        font-size: var(--fontSize-small);
        line-height: 1.5em;
        color: var(--cl-fontGrey2);
    }

    &__header &__logInfos {
        margin-block-end: 4.5rem;
    }
}
