// -------------------
// NAVIGATION
// -------------------
.navigation {
    background-color: var(--cl-light);
    border: 1px solid var(--cl-tableHeader);
    padding-block-start: 1.9rem;
    height: 5rem;
}
.navigation__content {
    display: flex;
    justify-content: space-between;
}
.navigation__tab-list {
    display: grid;
    grid-auto-flow: column;
    gap: 3.5rem;
}
.navigation__tab {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;

    color: var(--cl-fontDisabled);
    font-size: var(--fontSize-medium);
    font-weight: 500;
    line-height: 1.3rem;
    text-decoration: none;

    padding-inline-end: 1rem;
    padding-block-end: var(--size-16);

    cursor: pointer;
    transition: 0.12s;
}

.navigation__tab:hover,
.navigation__tab.active {
    color: var(--cl-fontDark);
}

.navigation__tabIcon {
    height: 1.363rem;
}

.navigation__tabLabel {
    line-height: 1em;
}

.navigation__tab.active::before {
    position: absolute;
    content: "";

    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;

    background-color: var(--cl-secondary);
}

.navigation__link-list {
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
}

.navigation__link {
    display: inline-grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
    align-self: start;
    margin: 0;
    padding: 0;

    font-family: var(--ft-familyMain);
    font-size: var(--fontSize-small);
    font-weight: 700;
    color: var(--cl-primary);
    background-color: transparent;
    border: none;

    cursor: pointer;
}

.navigation__link.danger {
    @extend .navigation__link;
    color: var(--cl-danger);
}

.navigation__linkIcon {
    height: 1.363rem;
}
