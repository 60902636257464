.license-card {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-column-gap: 1em;
    column-gap: var(--size-8);
    align-items: center;
    width: 100%;
}

.license-card .license-type {
    font-weight: bold;
    line-height: 2em;
}

.license-card .license-date {
    font-size: var(--fontSize-small);
    color: #8c8c8c;
    text-align: end;
}
