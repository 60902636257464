.support-section {
    padding: 24px;
    background-color: #f2f2f2; // Example background color
  
    a {
      display: block;
      color: #007bff; // Example link color
      text-decoration: none;
      margin-bottom: 8px;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }
  