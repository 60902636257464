.link {
    display: inline-grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
    align-self: start;
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: var(--ft-familyMain);
    font-size: var(--fontSize-main);
    line-height: 1.5em;
    font-weight: 700;
    color: var(--cl-primary);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.paragraph {
    font-size: var(--fontSize-small);
    line-height: 1.5em;
    color: var(--cl-fontGrey);
    letter-spacing: 0.03em;
}
