.drawer {
    height: 100%;
    width: 42.2rem;
    padding-inline: 3.1rem 2.1rem;
    padding-block: 3.1rem;
    background-color: var(--cl-light);

    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1000;
    box-shadow: -5px 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    visibility: hidden;
}

.drawer.open {
    right: 0;
    visibility: visible;
}

.drawer__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 2.99rem;
}
.drawer__title {
    font-size: var(--fontSize-medium);
    font-weight: 700;
    line-height: 1.857em;
}
.drawer__close {
    width: 3.9rem;
    height: 2.807rem;
    background: var(--cl-primary);
    color: var(--cl-light);
    border-radius: 3px;
    border: none;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.drawer__close i {
    font-size: var(--size-16);
}

.drawer__content p:not(.alert__text) {
    font-size: var(--fontSize-small);
    color: var(--cl-fontGrey2);
    line-height: 1.6em;
}
.drawer__content p:not(:last-child) {
    margin-block-end: 2rem;
}
.drawer__content strong {
    font-weight: var(--fontWeight-bold);
}

.drawer__content .drawer__link {
    font-size: var(--fontSize-small);
    font-weight: 700;
    line-height: 2.2rem;
    color: var(--cl-primary);
    text-decoration: none;

    display: flex;
    align-items: center;
    margin-top: 16px;
}

.drawer__content .drawer__link-icon {
    margin-inline-start: 1rem;
}

.drawer__content .alert {
    margin-bottom: var(--size-16);
}
