.ForgotPasswordForm {
    .formControl--withIcon:last-of-type {
        margin-block-end: var(--size-16);
    }

    .button--primary {
        margin-block-end: var(--size-16);
    }

    p:last-child {
        margin-block-start: var(--size-48);
    }
}
