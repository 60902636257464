.tableTopRow {
    padding-bottom: var(--size-16);
    padding-top: var(--size-8);
    display: flex;
    gap: 4.8rem;
}

.tableTopRow > *:not(:first-child) {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background: var(--cl-fontDisabled);
        left: calc(0rem - var(--size-24));
        opacity: 0.4;
    }
}
