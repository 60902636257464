.newUserForm {
    display: flex;
    flex-direction: column;

    .formInput,
    .formTextarea {
        padding-block-start: 1rem;
        padding-block-end: 1rem;
        font-size: var(--fontSize-medium);
        padding-inline-start: 5.8rem;
        resize: none;
    }

    .formInput__icon {
        top: 1.1rem;
    }

    button {
        // margin-block-start: auto;
        align-self: end;
    }
}
