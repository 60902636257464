.header {
    padding-block: 1.8rem;
    position: relative;
    z-index: 1000;
    background-color: var(--cl-background);
}

.header__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header__logo {
    height: 4.2rem;
}

.header__right {
    display: grid;
    grid-auto-flow: column;
    gap: 4rem;
    align-items: center;
}

.header__alert {
    position: relative;
    width: var(--size-24);
    height: var(--size-24);
    display: grid;
    place-items: center;
}

.header__alert.active::before {
    position: absolute;
    content: "";
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 1.1rem;
    border: 2px solid var(--cl-background);
    background-color: var(--cl-danger);
    top: 3px;
    right: 1px;
    box-sizing: border-box;
}

.header__alertIcon {
    height: 2.06rem;
}

.header__user {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
}

.header__username {
    font-size: 1.3rem;
    font-weight: 500;
}

.header__userPicture {
    height: 4rem;
    width: 4rem;
    border-radius: 4rem;
    overflow: hidden;
}

.header__userPictureImage {
    object-fit: cover;
    height: 4rem;
    width: 4rem;
}

.support__cta {
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;
    place-items: center;
}
