.QRCode__container {
    display: flex;
    align-items: center;
    margin-bottom: var(--size-32);
    flex-direction: column;
    gap: var(--size-24);
}

.QRCode__urlContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.QRCode__urlInput {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 4px 8px;

    margin-bottom: var(--size-24);
}

.QRCode__url {
    word-break: break-all;
}

.QRCode__urlContainer .navigation__link {
    align-self: auto;
}
