.license-manager {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea; 
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
  
    .license-card:not(:last-child) {
      margin-bottom: 16px;
      border-bottom: 1px solid #eaeaea;
    }
  }
  