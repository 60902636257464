@import "../../../styles/variables/";

.registerForm {
    width: 100%;
    margin-block-start: 3.5rem;
}

.registerForm__row {
    display: flex;
    justify-content: space-between;
    &:first-child {
        margin-block-start: var(--size-24);
    }
    &:not(:last-child) {
        margin-block-end: var(--size-48);
    }
}

.registerForm .formControl--withIcon:last-of-type {
    margin-block-end: 4rem;
}

.registerForm__rowInline {
    display: grid;
    justify-content: space-between;
    margin-block-end: var(--size-16);
    gap: var(--size-16);
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-auto-rows: auto;
}
.registerForm__rowInline .formControl,
.registerForm__rowInline .formControl--withIcon {
    margin-block-end: 0 !important;
}

.registerForm .formText {
    font-size: var(--fontSize-medium);
    color: var(--cl-fontGrey);
}

.registerForm .formText > .formLink {
    font-size: inherit;
    color: var(--cl-primary);
    text-decoration: none;
}

.registerForm .formLink {
    color: var(--cl-primary);
    text-decoration: none;
    font-size: var(--fontSize-medium);
    align-self: center;
}

.registerForm .formParagraph {
    font-size: var(--fontSize-medium);
    color: var(--cl-fontGrey);
    margin-block-end: var(--size-16);
    line-height: 1.2;
    text-align: justify;
}