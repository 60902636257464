:root {
    --hue: 207;
    --saturation: 100%;
    --light: 63%;
    --alpha: 100%;

    // ------------------------
    // main colors
    // ------------------------
    --cl-primary: hsla(207, 100%, 63%, 100%);
    --cl-primaryDark: hsl(211, 92%, 44%);
    --cl-primaryTransparent: hsla(207, 100%, 63%, 30%);
    --cl-primaryTransparent-light: hsla(207, 100%, 63%, 10%);

    --cl-secondary: hsla(51, 79%, 59%, 100%);

    // ------------------------
    // message and information colors
    // ------------------------
    --cl-success: hsla(98, 62%, 53%, 100%);
    --cl-successTransparent: hsla(98, 62%, 53%, 10%);
    --cl-success-dark: hsla(97, 79%, 41%, 100%);

    --cl-danger: hsla(359, 100%, 65%, 100%);
    --cl-dangerTransparent: hsla(359, 100%, 65%, 30%);
    --cl-dangerTransparent-light: hsla(359, 100%, 65%, 10%);

    --cl-warning: hsla(31, 96%, 53%, 100%);

    --cl-greenCta: hsla(181, 48%, 55%, 100%);

    // ------------------------
    // background colors
    // ------------------------
    --cl-background: hsla(0, 0%, 98%, 100%);
    --cl-backgroundLight: hsla(0, 0%, 100%, 100%);
    --cl-backgroundDashboard: hsla(0, 0%, 96%, 100%);
    --cl-backgroundTooltip: hsla(0, 0%, 15%, 100%);

    // ------------------------
    // fonts
    // ------------------------
    --cl-fontDark: hsla(0, 0%, 0%, 100%);
    --cl-fontGrey: hsla(0, 0%, 35%, 100%);
    --cl-fontGrey2: hsla(0, 0%, 43%, 100%);
    --cl-fontGreyLight: hsla(0, 0%, 77%, 100%);

    --cl-fontDisabled: hsla(210, 14%, 72%, 100%);
    --cl-fontDisabledLight: hsla(0, 0%, 85%, 100%);

    --cl-fontInputPlaceholder: hsla(0, 0%, 75%, 100%);

    // ------------------------
    // specific colors:
    // ------------------------
    --cl-tableHeader: hsla(210, 23%, 95%, 100%);
    --cl-light: hsla(0, 0%, 100%, 100%);
}
