.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(400px - 2rem), 1fr));
    grid-gap: var(--size-16);
    width: 100%;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--size-16);
    width: 100%;
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--size-16);
    width: 100%;
}


.grid-v-gapped {
    display: grid;
    flex-direction: column;
    gap: var(--size-16);
}

.grid-h-gapped {
    display: grid;
    gap: var(--size-16);
}
