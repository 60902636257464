.button {
    padding: 1.2rem;
    min-width: 16.9rem;
    font-family: var(--ft-familyMain);
    font-size: var(--fontSize-main);
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid var(--cl-fontGrey2);
    color: var(--cl-fontGrey2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.button.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.button.rounded {
    border-radius: 2px;
}

.button .button-icon {
    height: 0.78em;
    margin-inline-end: 1.2rem;
}

.button .button-iconForbidden {
    // height: 0.78em;
    margin-inline-start: var(--size-8);
    color: inherit;
    font-size: 1.5rem;
}

.button-outline {
    @extend .button;
    background-color: transparent;
}

.button--primary {
    @extend .button;
    background-color: var(--cl-primary);
    color: var(--cl-light);
    border: 1px solid var(--cl-primary);
}

.button--danger {
    @extend .button;
    background-color: var(--cl-danger);
    color: var(--cl-light);
    border: 1px solid var(--cl-danger);
}

.button--block {
    width: 100%;
}

.button-outline--primary {
    @extend .button--primary;
    background-color: transparent;
    color: var(--cl-primary);
    border: 1px solid var(--cl-primary);
}

.button-outline--danger {
    @extend .button--primary;
    background-color: transparent;
    color: var(--cl-danger);
    border: 1px solid var(--cl-danger);
}
