.u-mb-16 {
    margin-bottom: var(--size-16);
}

.my-4 {
    margin-block: 1rem;
}

.my-8 {
    margin-block: 2rem;
}
