.setting {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--size-16);
}

// -----------------------------------------
// CARD SECTION
// -----------------------------------------
.settingCard {
    // font-size:
    font-size: var(--fontSize-small);
    line-height: 2.2rem;
    background-color: var(--cl-light);
    padding-block: var(--size-24);
    padding-inline: 0;
    border: 1px solid var(--cl-tableHeader);
}

.settingCard__title {
    font-size: var(--fontSize-medium);
    font-weight: var(--fontWeight-bold);
}

.settingCard__subtitle {
    color: var(--cl-fontGrey2);
}

.settingCard__body {
    padding-block-start: var(--size-16);
}

.settingCard__content {
    padding: 0 var(--size-24);
}

// -----------------------------------------
// TABLE
// -----------------------------------------
.settingTable {
    display: grid;
    grid-auto-flow: row;
    row-gap: 8px;
}
.settingTable__row {
    box-sizing: border-box;
    display: flex;
    gap: var(--size-24);
}

.settingTable__key {
    width: 16rem;
    font-weight: var(--fontWeight-medium);
}

.settingTable__value {
    color: var(--cl-fontGrey2);
}

.settingTable__link {
    text-decoration: none;
    color: var(--cl-primary);
    font-weight: var(--fontWeight-bold);
}

.settingTable__bottom {
    padding-block: var(--size-24) 0;
    padding-inline: var(--size-24);
    border-top: 1px solid #eff2f5;
    margin-block-start: var(--size-24);
}
.settingTable__button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--cl-primary);
}
.settingTable__buttonIcon {
    margin-block-start: -0.2rem;
}

.settingTable__button.danger {
    color: var(--cl-danger);
}

// -----------------------------------------
// Markdown content
// -----------------------------------------
.settingCard__body {
    p {
        margin-block-end: 2rem;
        font-size: var(--fontSize-small);
        color: var(--cl-fontGrey2);
        line-height: 1.6em;
    }

    em {
        font-weight: 700;
    }

    p.danger {
        color: var(--cl-danger);
    }
}
