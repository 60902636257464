.home-card {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    padding: var(--size-16);
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    .title {
        font-weight: bold;
        line-height: 2em;
    }

    .button--primary {
        font-size: var(--fontSize-medium);
        line-height: 1.7em;
        padding: var(--size-4) var(--size-16);
        border-radius: var(--size-2);
    }
}

.card_cta {
    display: flex;
    flex-direction: column;
    gap: var(--size-16);
}

.link-card {
    display: flex;
    justify-items: start;
    width: 100%;
    padding: var(--size-16);
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    .link {
        line-height: 2em;
    }
}

.community-card {
    display: flex;
    justify-content: space-between;

    gap: var(--size-16);

    .title {
        font-size: var(--fontSize-main);
        line-height: 1.28em;
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: var(--size-16);
    }
}

.community-card-no-text {
    display: flex;
    gap: var(--size-16);

    .community-card__header {
        display: flex;
        flex-direction: column;
    }

    .title {
        line-height: 1.28em;
    }
}

.community-card__list {
    display: flex;
    flex-direction: column;
    gap: var(--size-16);
}

[class^="community-card"] .link {
    font-size: var(--fontSize-medium);
    line-height: 1.8em;
    height: 1.8em;
    display: block;
}
