:root {
    // ------------------------
    // font family
    // ------------------------
    --ft-familyMain: "Montserrat";

    // ------------------------
    // font sizes
    // ------------------------
    --fontSize-large: 2.2rem;
    --fontSize-main: 1.6rem;
    --fontSize-medium: 1.4rem;
    --fontSize-small: 1.2rem;
    --fontSize-xsmall: 1rem;

    // ------------------------
    // font weight
    // ------------------------
    --fontWeight-regular: 400;
    --fontWeight-medium: 600;
    --fontWeight-bold: 700;

    // ------------------------
    // font block
    // ------------------------
}
