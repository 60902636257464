:root {
    --loaderCircleSize: 5rem;
}

.loadingScreen {
    position: relative;
    min-height: 29rem;
    height: 50%;
    max-height: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        height: var(--loaderCircleSize);
        width: var(--loaderCircleSize);
        border: 0.2rem solid var(--cl-tableHeader);
        border-top-color: var(--cl-primary);
        border-bottom-color: var(--cl-primary);
        border-radius: var(--loaderCircleSize);

        animation: loadingPulse ease 1s infinite;
    }
}

.loadingScreen.contained {
    height: 100%;
    width: 100%;
    min-height: 2rem;
    min-width: unset;

    --loaderCircleSize: 2.4rem;
}

@keyframes loadingPulse {
    to {
        transform: rotate(2turn);
    }
}
