.formControl {
    position: relative;
    margin-block-end: var(--size-16);
}

.formControl--withIcon {
    @extend .formControl;
}

.formControl.error .formInput,
.formControl--withIcon.error .formInput,
.formControl.error .formTextarea,
.formControl--withIcon.error .formTextarea {
    border: 1px solid var(--cl-danger);
    &::-webkit-input-placeholder {
        color: var(--cl-danger);
    }
}

.formControl--withIcon .formInput,
.formControl--withIcon .formTextarea {
    padding-inline-start: var(--size-48);
}
