.tag {
    height: var(--size-24);
    background-color: transparent;
    padding: 0;
    border: none;
    text-decoration: none;
    color: var(--cl-primary);
    border: 1px solid var(--cl-primary);
    padding-block: var(--size-4);
    padding-inline: var(--size-8);
    border-radius: var(--size-16);
    font-size: 1.1rem;
    font-weight: var(--fontWeight-medium);
}

.tag.success {
    color: var(--cl-success);
    border-color: var(--cl-success);
    background-color: var(--cl-successTransparent);
}

.tag.danger {
    color: var(--cl-danger);
    border-color: var(--cl-danger);
    background-color: var(--cl-dangerTransparent-light);
}
