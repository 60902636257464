.table {
    text-align: left;
    font-size: var(--fontSize-small);
    line-height: 2.2rem;
}

.table__head {
    background: var(--cl-tableHeader);
    border: 1px solid var(--cl-tableHeader);
    height: 5rem;
    font-weight: 700;
}

.table__headRow {
    height: 5rem;
}

.table__row {
    border: 1px solid var(--cl-tableHeader);
    background: var(--cl-light);
    height: 5rem;
    color: var(--cl-fontGrey2);
}
.table__row:not(.backgroundFix):hover {
    background: var(--cl-backgroundDashboard);
}

.table__row:hover .table__Id {
    color: var(--cl-fontDark);
}

.table .tooltip__img,
.table__manage-button {
    vertical-align: middle;
}

.table__cell {
    position: relative;
}

.table__cell-delete {
        width: 10rem;
    height: var(--size-24);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:  var(--cl-dangerTransparent-light);
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    color: var(--cl-danger);
    border: 1px solid var(--cl-danger);
    padding-block: var(--size-4);
    padding-inline: var(--size-8);
    border-radius: var(--size-16);
    font-size: 1.1rem;
    font-weight: var(--fontWeight-medium);
    transition: 0.12s;
    &:hover {
        background-color: var(--cl-danger);
        opacity: 0.8;
        color: white;
    }
    &:not(:last-child) {
        margin-inline-end: 2rem;
    }

}

.table__Id {
    width: 18rem;
    font-size: var(--fontSize-xsmall);
    font-weight: var(--fontWeight-medium);
    padding-inline-end: var(--size-8);
}
.table__status-content {
    position: relative;
}
.table__status-content.active {
    color: var(--cl-success);
}
.table__status-content.expired {
    color: var(--cl-danger);
}
.table__status-content.not-valid {
    color: var(--cl-danger);
}
.table__status-content.pending {
    color: var(--cl-primary);
}
.table__status-button--renew {
    border: 1px solid var(--cl-warning);
    color: var(--cl-warning);
    border-radius: 2px;
    background: transparent;
    text-transform: uppercase;
    font-size: var(--fontSize-small);
    line-height: 2rem;
    padding-block: var(--size-2);
    padding-inline: var(--size-4);
    margin-inline-start: 1rem;
    cursor: pointer;
}

.table__manage-button {
    width: 10rem;
    height: var(--size-24);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    color: var(--cl-primary);
    border: 1px solid var(--cl-primary);
    padding-block: var(--size-4);
    padding-inline: var(--size-8);
    border-radius: var(--size-16);
    font-size: 1.1rem;
    font-weight: var(--fontWeight-medium);
    transition: 0.12s;
    &:hover {
        background-color: var(--cl-primaryTransparent-light);
    }
    &:not(:last-child) {
        margin-inline-end: 2rem;
    }
}
.table__manage-button img {
    height: 1.36rem;
}

.table__checkboxContainer {
    position: relative;
    display: flex;
    font-size: var(--fontSize-medium);
    line-height: 1.57em;
    align-items: center;
    padding-inline-start: 2rem;
    cursor: pointer;
    user-select: none;
    width: var(--size-16);
    height: var(--size-16);
}
.table__checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
}
.table__checkmark {
    position: absolute;
    display: inline-block;
    width: var(--size-16);
    height: var(--size-16);
    border: 1px solid var(--cl-fontDisabled);
    left: 0;
    border-radius: 2px;
    overflow: hidden;
}
.table__checkboxContainer:hover input ~ .table__checkmark {
    background: var(--cl-fontGreyLight);
}
.table__checkboxContainer input:checked ~ .table__checkmark {
    background: var(--cl-primary);
    border: none;
}
.table__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}
.table__checkboxContainer input:checked ~ .table__checkmark::after {
    display: block;
}
.table__checkboxContainer input:checked ~ .table__checkmark::after {
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.table-info {
    font-size: var(--fontSize-small);
    line-height: 2.2rem;
    margin-block-start: 2.1rem;
    text-align: center;
    color: var(--cl-fontGreyLight);
}

.table th,
.table td,
.table__cell {
    vertical-align: middle;
    table-layout: auto;
    width: 15rem;
    padding-right: var(--size-16);
}

th.u-cell-width-24 {
    width: 24rem;
}
.table thead th:first-child,
.table__cell:first-child {
    padding-inline-start: 2rem;
}
