@import "../../styles/main.scss";

.dashboard {
    position: relative;
    background-color: var(--cl-background);
    overflow: hidden;

    // -------------------
    // Layout & Container
    // -------------------
    .container {
        max-width: calc(123.5rem + 3.2rem);
        margin: 0 auto;
        padding-inline-start: var(--size-16);
        padding-inline-end: var(--size-16);
    }

    // -------------------
    // Board
    // -------------------
    .board {
        background-color: var(--cl-background);
    }

    .board__content {
        padding-block-start: 1.9rem;
        padding-block-end: 1.9rem;
        min-height: calc(100vh - 13rem);
        display: grid;
        grid-template-columns: auto 30rem;
        gap: 4.4rem;
    }

    .board__content--full {
        @extend .board__content;
        grid-template-columns: unset;
        grid-auto-flow: column;
    }

    .board__main,
    .board__home {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
