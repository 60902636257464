.buttonTag {
    height: var(--size-24);
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: var(--cl-primary);
    border: 1px solid var(--cl-primary);
    padding-block: var(--size-4);
    padding-inline: var(--size-8);
    border-radius: var(--size-16);
    font-size: 1.1rem;
    font-weight: var(--fontWeight-medium);
    transition: 0.12s;
    &:hover {
        background-color: var(--cl-primaryTransparent-light);
    }
    &:not(:last-child) {
        margin-inline-end: 2rem;
    }
}
