@import "../../../styles/variables/";

.loginForm {
    width: 100%;
    margin-block-start: 3.5rem;
}

.loginForm__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
        margin-block-start: var(--size-24);
    }
    &:not(:last-child) {
        margin-block-end: var(--size-48);
    }
}

.loginForm__row--center {
    @extend .loginForm__row;
    justify-content: center;
}

.loginForm .button--primary {
    align-self: flex-start;
    min-height: 5.2rem;
}

.loginForm .button--block {
    margin-bottom: var(--size-16);
}

.loginForm .formControl--withIcon:last-of-type {
    margin-block-end: 4rem;
}

.loginForm .formText {
    font-size: var(--fontSize-medium);
    color: var(--cl-fontGrey);
}

.loginForm .formText > .formLink {
    font-size: inherit;
    color: var(--cl-primary);
    text-decoration: none;
}

.loginForm .formLink {
    color: var(--cl-primary);
    text-decoration: none;
    font-size: var(--fontSize-medium);
}
