@use "../../../styles/abstract/" as *;

.login {
    position: relative;
    background: var(--cl-background);
    width: 100vw;
    min-height: 100vh;
    padding-block: var(--size-48) var(--size-32);
    padding-inline: var(--size-32);
    overflow: hidden;
}

.login .shape {
    position: absolute;
    width: 48rem;
    max-width: 48rem;
    top: 15.6rem;
    right: 0;
    left: calc(100vw - 27rem);

    @media only screen and (max-width: 1150px) {
        width: 40rem;
        left: calc(100vw - 20rem);
    }
    @media only screen and (max-width: 1020px) {
        display: none;
    }
}

.login .login__logo {
    max-width: 24rem;
    margin: auto;
}

.login .title-1 {
    font-size: var(--fontSize-large);
    line-height: 1.3em;
    font-weight: 700;
    margin-block-end: 1.4rem;
}

.login .text-large {
    font-size: var(--fontSize-main);
    line-height: 1.3em;
}

@include responsive-max(768px) {
    .login {
        padding-block: 7rem 4rem;
        padding-inline: 4rem;
    }

    .login .title-1 {
        font-size: var(--fontSize-large);
    }
}
