.tooltip__container {
    position: relative;
}

.tooltip__content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    font-size: var(--fontSize-xsmall);
    font-weight: 500;
    line-height: 2.2rem;
    color: var(--cl-light);
    background: var(--cl-backgroundTooltip);
    padding-block: 0.5rem;
    padding-inline: 0.8rem;
    white-space: nowrap;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 350px;
    transition: 0.12s;

    &::before {
        content: "";
        position: absolute;
        background-color: inherit;
        height: 12px;
        width: 12px;
        bottom: -50%;
        left: 50%;
        transform: translate(-50%, -100%) rotate(45deg);
        z-index: -1;
    }
}

.tooltip__container:hover .tooltip__content {
    visibility: visible;
    opacity: 1;
}
