.formCheckox {
}

.formCheckbox__checkmark {
    position: absolute;
    display: inline-block;
    width: var(--size-16);
    height: var(--size-16);
    border: 1px solid var(--cl-fontDisabled);
    left: 0;
    border-radius: 2px;
    overflow: hidden;
}

.formCheckbox__container {
    position: relative;
    display: flex;
    font-size: var(--fontSize-small);
    line-height: 1em;
    align-items: center;
    padding-inline-start: 2rem;
    cursor: pointer;
    user-select: none;

    margin-top: 16px;
    margin-bottom: 16px;
}

.formCheckbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
}

.formCheckbox__container:hover input ~ .formCheckbox__checkmark {
    background: var(--cl-fontGreyLight);
}

.formCheckbox__container input:checked ~ .formCheckbox__checkmark {
}

.formCheckbox__container input:checked ~ .formCheckbox__checkmark {
    background: var(--cl-primary);
    border: none;
}

.formCheckbox__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.formCheckbox__container input:checked ~ .formCheckbox__checkmark::after {
    display: block;
}

.formCheckbox__container input:checked ~ .formCheckbox__checkmark::after {
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}
