:root {
    --size-2: 0.2rem;
    --size-4: 0.4rem;
    --size-8: 0.8rem;
    --size-16: 1.6rem;
    --size-24: 2.4rem;
    --size-32: 3.2rem;
    --size-48: 4.8rem;
    --size-64: 6.4rem;
    --size-96: 9.6rem;
}
