.licenseDetail > .alert {
    margin-block-end: 1rem;
}

.licenseDetail {
}

.licenseDetail__grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    gap: 1rem;
}

.licenseDetail__navigation {
    display: flex;
    gap: var(--size-24);
    background: white;
    padding-inline: var(--size-24);
    border-block-end: 1px solid var(--cl-tableHeader);
}

.licenseDetail .navigation__tab {
    padding-block-start: var(--size-16);
    padding-inline-end: 0;
    text-align: center;
}

// -------------------------
// Content section
// -------------------------
.licenseDetail__content {
    display: grid;
    grid-template-columns: 29.1rem 1fr;
    gap: 4.5rem;
}

.licenseDetail__container {
    padding: var(--size-24);
    background: var(--cl-light);

    height: fit-content;
    padding-block: 1rem;
    min-height: 27rem;
}

.licenseDetail__informations {
    max-width: 29.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.licenseDetail__informations .alert {
    margin-block-start: var(--size-8);
}

.licenseDetail__copyKey {
    font-weight: 700;
    cursor: pointer;
}

// -------------------------
// Tabs section
// -------------------------
.licenseDetail__tabSection {
    background-color: var(--cl-light);
}

.licenseDetail__tabs {
    display: grid;
    grid-auto-flow: column;
    gap: 5.3rem;
    grid-auto-columns: max-content;

    height: 5rem;
    padding-block: 1.4rem 0;
    padding-inline: 3rem;
    border-bottom: 1px solid var(--cl-tableHeader);
}
.licenseDetail__tab {
    position: relative;
    font-size: var(--fontSize-xsmall);
    font-weight: 700;
    line-height: 2.2rem;
    color: var(--cl-fontDisabled);
    text-transform: uppercase;
    cursor: pointer;
}
.licenseDetail__tab.active,
.licenseDetail__tab:hover {
    color: var(--cl-primary);
}

.licenseDetail__tab.active::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: var(--cl-secondary);
}

// -------------------------
// Tab Content section
// -------------------------
// table
.licenseDetail__table {
    font-size: var(--fontSize-small);
    line-height: 2.2rem;
}

.licenseDetail__tableRow {
    height: var(--size-32);
}

.licenseDetail__tableLabel {
    width: 11rem;
    font-weight: 700;
    vertical-align: top;
    text-transform: capitalize;
}
.licenseDetail__tableValue {
    color: var(--cl-fontGrey2);
    vertical-align: top;
}

.licenseDetail__tableIcon {
    height: 2.391rem;
    vertical-align: middle;
}

// -------------------------
// Form section
// -------------------------
.licenseDetail__formSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.licenseDetail__formHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: var(--fontSize-small);
    line-height: 2.2rem;
    font-weight: 700;
    text-decoration: none;
}

.licenseDetail__formHeaderInfo {
    color: var(--cl-fontGrey2);
}
.licenseDetail__formHeader-button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: var(--fontWeight-bold);
    color: var(--cl-primary);
    text-decoration: none;
    cursor: pointer;
}

.licenseDetail__formSection .licenseDetail__form {
    margin-block-start: 1.1rem;
}

.licenseDetail__form {
    // gap: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.licenseDetail__form textarea {
    min-height: 30rem;
    height: 100%;
    font-family: var(--ft-familyMain);
    font-size: var(--fontSize-small);
    resize: none;
    width: 100%;
    padding-block: 2.7rem;
    padding-inline: 2rem;
    border: 1px solid var(--cl-tableHeader);

    &::-webkit-input-placeholder {
        color: var(--cl-fontDisabled);
    }
}

.licenseDetail__formSection .button-section {
    display: grid;
    grid-auto-flow: column;
    gap: 3.8rem;
    align-content: end;
    height: fit-content;
    align-self: flex-end;
}

.licenseDetail__errorSection {
    height: clamp(10rem, calc(100vh - 18rem), 30rem);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--size-48);
}

.licenseDetail__error {
    font-size: var(--fontSize-large);
    margin-block-end: var(--size-8);
    font-weight: 700;
}

.licenseDetail__errorList {
    margin-block: var(--size-8);
    margin-inline: 0;
}

.licenseDetail__errorList-item a {
    color: var(--cl-primary);
    font-size: var(--fontSize-small);
    font-weight: 600;
    margin-block-end: var(--size-8);
    text-decoration: none;
}

.detailRow {
    position: relative; 
    span[role="button"] {
        position: relative;
        cursor: pointer;

        &:hover + .iconCopy {
            visibility: visible; 
            color: #888;
        }
    }

    .iconCopy {
        visibility: hidden;
        position: absolute;
        right: -1rem;
        top: 50%;
        
        transform: translateY(-50%);
        color: #aaa; 
        width: 14px;
        height: 14px;
        &:hover {
            fill: #888;
        }

        &.clicked {
            fill: #555; 
        }
    }
}
