// -------------------------------------------
// Font import
// -------------------------------------------
@font-face {
	font-family: "Montserrat";
	src: url("../../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: "Montserrat";
	src: url("../../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "Montserrat";
	src: url("../../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
	font-weight: 600;
}
@font-face {
	font-family: "Montserrat";
	src: url("../../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
	font-weight: 700;
}
