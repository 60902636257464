.LicenseNotification {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--size-16);

    padding: var(--size-16);

    min-height: 6.4rem;
    background-color: #e6f4ff;
    border-radius: var(--size-2);
    overflow: hidden;

    box-shadow: var(--shadow-main);

    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;

        height: 100%;
        width: var(--size-4);
        background-color: #40a9ff;
    }

    &__Content {
        font-size: var(--fontSize-main);
        font-weight: var(--fontWeight-bold);
        line-height: 2em;
    }

    &__Cta [class^="button--"] {
        min-width: 8rem;
        padding: var(--size-4) var(--size-16);
        height: 3.2rem;

        font-size: var(--fontSize-medium);
        line-height: 1.7em;
        border-radius: var(--size-2);
    }
}
