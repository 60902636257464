.formInput {
    border: 1px solid var(--cl-fontDisabledLight);
    padding-block: var(--size-16);
    padding-inline: var(--size-24);
    width: 100%;
    font-size: var(--fontSize-main);
    &::-webkit-input-placeholder {
        color: var(--cl-fontInputPlaceholder);
    }

    &:focus {
        outline-color: var(--cl-primary);
    }
}

.formInput.noArrow {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
}

.formInput.small {
    font-size: var(--fontSize-small);
}

.formInput.error {
    color: var(--cl-danger);
}

.formInput.bold {
    font-weight: var(--fontWeight-bold);
}

.formInput--password {
    padding-inline-end: var(--size-48);
}

.formInput__icon {
    position: absolute;
    top: 1.8rem;
    left: var(--size-24);
}

.formTextarea {
    @extend .formInput;
    font-family: var(--ft-familyMain);
    height: 16rem;
}

.formInput__passwordButton {
    position: absolute;
    top: 1.7rem;
    right: var(--size-24);
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--cl-fontInputPlaceholder);
}
.formInput__passwordIcon {
    height: 1.8rem;
    color: inherit;
    fill: var(--cl-fontInputPlaceholder);
}
