@mixin responsive-max($size) {
	@media only screen and (max-width: $size) {
		@content;
	}
}

@mixin responsive-min($size) {
	@media only screen and (min-width: calc($size + 1)) {
		@content;
	}
}

@mixin responsive-range($minSize, $maxSize) {
	@media only screen and (min-width: calc($minSize + 1)) and (max-width: $maxSize) {
		@content;
	}
}
